import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ObservableOperation, OperationObserverService } from '@x/common/operation';
import { ProductAssociationService } from '@x/ecommerce/domain-client';
import {
  ChannelContext,
  ChannelItemCollectionProvider,
  ProductRecommendationModelItemCollectionProvider,
  TaxonItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { ProductAssociationTarget } from '@x/schemas/ecommerce';
import { Subject, takeUntil, tap } from 'rxjs';

export interface IProductAssociationFormDialogData {
  id?: number;
  channelId?: number;
}

export interface IProductAssociationFormDialogResult {
  id?: number;
}

@Component({
  selector: 'x-product-association-form-dialog',
  templateUrl: './product-association-form-dialog.component.html',
})
export class ProductAssociationFormDialogComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  operation$: ObservableOperation;

  actionLabel?: 'Create' | 'Update';

  Providers = {
    ChannelItemCollectionProvider,
    TaxonItemCollectionProvider,
    ProductRecommendationModelItemCollectionProvider,
  };

  locales$ = this.channelContext.getLocalesInAllChannels();

  private titleControls = new FormGroup({});
  getTitleControl(locale: string): FormControl {
    let control: FormControl = this.titleControls.get(locale) as FormControl;

    if (!control) {
      control = new FormControl(null, Validators.required);
      this.titleControls.setControl(locale, control);
    }

    return control;
  }

  formGroup = new FormGroup({
    title: new FormControl<string | null>(null, [Validators.required]),
    enabled: new FormControl<boolean>(false),
    channelIds: new FormControl<number[]>([]),
    recommendationModel: new FormControl<string | null>(null),
    targetTaxonIds: new FormControl<number[]>([]),
    sourceTaxonId: new FormControl<number | null>(null),
    sourceMainTaxonEnable: new FormControl<boolean>(false),
    targetMainTaxonEnable: new FormControl<boolean>(true),
    targets: new FormControl<ProductAssociationTarget[]>([], Validators.minLength(1)),
    testCase: new FormControl<string | null>(null),
  });

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: IProductAssociationFormDialogData,
    public readonly dialog: MatDialogRef<
      ProductAssociationFormDialogComponent,
      IProductAssociationFormDialogResult
    >,
    private readonly snackbar: MatSnackBar,
    private readonly channelContext: ChannelContext,
    private readonly operations: OperationObserverService,
    private readonly associationService: ProductAssociationService,
  ) {}

  ngOnInit(): void {
    if (this.data.id) {
      this.actionLabel = 'Update';
      this.operation$ = this.operations.observe(
        this.associationService.fetchDetail(this.data.id).pipe(
          takeUntil(this._destroy$),
          tap(
            ({
              title,
              enabled,
              channels,
              targetTaxons,
              sourceTaxon,
              sourceMainTaxonEnable,
              targetMainTaxonEnable,
              targets,
              translations,
              recommendationModel,
              testCase,
            }) => {
              this.formGroup.patchValue({
                title,
                enabled,
                channelIds: channels.map(({ id }) => id),
                sourceTaxonId: sourceTaxon?.id,
                sourceMainTaxonEnable,
                targetMainTaxonEnable,
                targetTaxonIds: targetTaxons.map(({ id }) => id),
                targets,
                recommendationModel,
                testCase,
              });

              translations.forEach((t) => {
                this.getTitleControl(t.locale).setValue(t.title);
              });

              // this.titleControls.patchValue(
              //   Object.fromEntries(translations.map(({ locale, title }) => [locale, title])),
              // );
            },
          ),
        ),
      );
    } else {
      this.actionLabel = 'Create';
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  async submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    const form = this.formGroup.value;

    if (this.data.id) {
      this.operation$ = this.operations.observe(
        this.associationService
          .update({
            id: this.data.id,
            title: String(form.title),
            channelIds: form.channelIds,
            enabled: form.enabled,
            sourceMainTaxonEnable: form.sourceMainTaxonEnable,
            targetMainTaxonEnable: form.targetMainTaxonEnable,
            sourceTaxonId: form.sourceTaxonId,
            targetTaxonIds: form.targetTaxonIds,
            targets: form.targets ?? [],
            translations: Object.entries(this.titleControls.value).map(([locale, title]) => ({
              locale,
              title: title as string,
            })),
            recommendationModel: form.recommendationModel,
            testCase: form.testCase,
          })
          .pipe(
            tap((data) => {
              this.dialog.close({ id: data?.id });
            }),
          ),
      );
    } else {
      this.operation$ = this.operations.observe(
        this.associationService
          .create({
            title: String(form.title),
            channelIds: form.channelIds,
            enabled: form.enabled,
            sourceMainTaxonEnable: form.sourceMainTaxonEnable,
            targetMainTaxonEnable: form.targetMainTaxonEnable,
            sourceTaxonId: form.sourceTaxonId,
            targetTaxonIds: form.targetTaxonIds,
            translations: Object.entries(this.titleControls.value).map(([locale, title]) => ({
              locale,
              title: title as string,
            })),
            targets: form.targets ?? [],
            testCase: form.testCase,
          })
          .pipe(
            tap((data) => {
              this.dialog.close({ id: data?.id });
            }),
          ),
      );
    }
  }
}
