<h2 mat-dialog-title>{{ actionLabel }} Product Association</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="product_association_create_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="disabled"
  xFormErrorBoundary
  [xOperation]="operation$ | async"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <!-- title -->
      <mat-form-field class="mb-1">
        <mat-label>Association Title (canonical)</mat-label>
        <input matInput type="text" formControlName="title" required autocomplete="disabled" />
        <mat-hint>Only admins can see this</mat-hint>
      </mat-form-field>
      <mat-form-field *ngFor="let locale of locales$ | async">
        <mat-label>Association Title ({{ locale }})</mat-label>
        <input
          matInput
          type="text"
          [formControl]="getTitleControl(locale)"
          required
          autocomplete="disabled"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Test Case</mat-label>
        <input matInput type="text" formControlName="testCase" autocomplete="disabled" />
        <mat-hint>
          Used for A/B testing purposes. Only change this if you know what you are doing!
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mt-2">
        <mat-label>
          <fa-icon icon="store"></fa-icon>
          Channels
        </mat-label>
        <x-data-autocomplete
          [provider]="Providers.ChannelItemCollectionProvider"
          formControlName="channelIds"
          [multiple]="true"
        ></x-data-autocomplete>
        <mat-hint>Set the display channels for the association on the shop</mat-hint>
      </mat-form-field>

      <mat-slide-toggle class="col-12 pt-3 px-3 pb-4" formControlName="enabled">
        Enabled
      </mat-slide-toggle>

      <div class="row p-0">
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <fa-icon class="pe-1" icon="bullseye-pointer"></fa-icon>
              Targeting Configuration
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <mat-form-field class="p-0">
              <mat-label>Targets</mat-label>
              <x-enum-select
                formControlName="targets"
                enum="ProductAssociationTarget"
                [multiple]="true"
                placeholder="No Targets"
              ></x-enum-select>
              <mat-hint>Configure the shop UI location targets for this association</mat-hint>
            </mat-form-field>

            <mat-form-field class="p-0">
              <mat-label>
                <fa-icon icon="layer-group"></fa-icon>
                Target Taxons
              </mat-label>
              <x-data-autocomplete
                [provider]="Providers.TaxonItemCollectionProvider"
                formControlName="targetTaxonIds"
                [multiple]="true"
              ></x-data-autocomplete>
              <mat-hint>Which taxons' products to make this association for</mat-hint>
            </mat-form-field>
          </div>

          <mat-slide-toggle class="col-12 p-1 pt-3" formControlName="targetMainTaxonEnable">
            Target Only Main Taxon & Ascendents
          </mat-slide-toggle>
        </mat-expansion-panel>
      </div>

      <div class="row p-0">
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <fa-icon class="pe-1" icon="database"></fa-icon>
              Sourcing Configuration
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <mat-form-field class="p-0">
              <mat-label>
                <fa-icon icon="layer-group"></fa-icon>
                Source Taxon
              </mat-label>
              <x-data-autocomplete
                [provider]="Providers.TaxonItemCollectionProvider"
                formControlName="sourceTaxonId"
              ></x-data-autocomplete>
              <mat-hint>
                These taxons and their children will be used to source products from
              </mat-hint>
            </mat-form-field>

            <mat-slide-toggle class="col-12 p-1 pt-3" formControlName="sourceMainTaxonEnable">
              Source from Main Taxons
            </mat-slide-toggle>

            <mat-form-field class="mt-3">
              <mat-label>Recommendation Model</mat-label>
              <x-data-select
                emptyOption="true"
                emptyLabel="- Don't use recommendation model -"
                formControlName="recommendationModel"
                [provider]="Providers.ProductRecommendationModelItemCollectionProvider"
              ></x-data-select>
              <mat-hint>
                Select a recommendation model to use in addition to other sourcing configuration
              </mat-hint>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </div>
    </x-form-panel>
  </x-dashboard-form-layout>
  <x-dashboard-errors *xOperationError="let error" [errors]="[error]"></x-dashboard-errors>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button
    mat-button
    [disabled]="formGroup.invalid"
    type="submit"
    color="primary"
    form="product_association_create_form"
  >
    {{ actionLabel }}
  </button>
</div>
